import React from "react"
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet"
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"

const PythonClass = ({
    data: {
        site
    },
}) => {
    return (
        <Layout>
            <Helmet>
                <title>Join my Python Class - 28th Feb</title>
                <meta name="description" content={"A brief overview"} />
            </Helmet>
            <div className="flex">
                <div className="md:max-w-1xl sm:mx-auto">
                    <div className="relative px-12 bg-white shadow-lg rounded-2xl p-10">
                        <h1 className="flex justify-center text-3xl text-black font-bold py-5">Python Live Class on Sat 26th Feb</h1>
                        <div className="max-w-md mx-auto">
                            <div className="relative shadow mx-auto h-40 w-40 border-white rounded-full overflow-hidden border-4">
                                <img className="object-cover w-full h-full" src="/assets/me.jpeg" />
                            </div>
                            <h1 className="text-lg text-center text-black font-semibold py-2">
                                Hosted by Imdad Ahad
                            </h1>
                            <div className="flex flex-col justify-center py-2 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <Link to='https://bit.ly/33o78Rw' className="button -primary font-bold text-center">Register</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default PythonClass
export const pageQuery = graphql`
  query PythonClassQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`